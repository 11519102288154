/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import _ from 'lodash';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { StyledModal, StyledHeader, StyledBody, CloseIcon, HeaderText,  PostButtonWrapper, PostButtonCard, TopSectionV2 } from './styles';
import { NoComments, FeedsContainer, ScrollableFeedsContainer
} from '../SocialFeedsV2/styles';
import SingleFeed from '../SocialFeedsV2/SingleFeed';
import Comments from '../Comments';
import Loading from '../Loading';
import { imageErrorMessage, ImageUrl } from '../../utils/constants';
import { checkImage, applyHighlights } from '../../utils/methods';
import { getUserProfileImage, addCommentReplyOnPost, addSocialInspiration, getCommentsOnPost, getUsersToShoutout, getSuggestionsList, getUserName, getUserProfile } from '../../redux/actions';
import SocialFeedTextarea from '../SocialFeedsV2/socialfeedTextAreaPost';
import { getListOfConnectedDevices, revokeDevice, getCompanyExemplifiesValues, editSocialInspiration, addCommentOnPost } from '../../redux/actions';
import { LaunchEventIcon, feedCloseIcon } from '../../utils/icons';
import { withTranslation } from 'react-i18next';

class WritePost extends Component {
  constructor(props) {
    super(props);
    const { editPostStatus, editDetails } = props;
    this.state = {
      imgSrc: editPostStatus && editDetails.image ? `https://s3.amazonaws.com/images.trainingamigo.com/${editDetails.image}` : "",
      inspirationQuote: editPostStatus ? editDetails.description : '',
      showCommentsFor: '',
      pageNumber: 1,
      showEditDeletePopup: '',
      editDetails: editDetails,
      editAndDelete: false,
      isUploadedImage: false,
      refreshFileReader: false,
      position: { start: 0, end: 0 },
      imgSrcArray:editPostStatus && editDetails.post_images && editDetails.post_images.length > 0 ? this.getImageDetails(editDetails.post_images) : [],
      mentionedUsers: editPostStatus && editDetails.mentioned_users.length > 0 ? this.getTaggedUserDetails(editDetails.mentioned_users, 0) : [],
      shoutoutType: 0,
      users: editPostStatus && editDetails.mentioned_users.length > 0 ? this.getTaggedUserDetails(editDetails.mentioned_users, 3) : '',
      showGivenShoutout: editPostStatus && editDetails.shoutout_user_name !== null ? true : false,
      shoutoutImage: editPostStatus && editDetails.recognition_image !== null ? editDetails.recognition_image : null,
      shoutoutText: editPostStatus && editDetails.core_value !== null ? editDetails.core_value : null,
      userIdArray: editPostStatus && editDetails.mentioned_users.length > 0 ? this.getTaggedUserDetails(editDetails.mentioned_users, 0) : [],
      taggedUser: editPostStatus && editDetails.mentioned_users.length > 0 ? this.getTaggedUserDetails(editDetails.mentioned_users, 1) : '',
      postDisabled: true,
      exemplifyID: editPostStatus && editDetails.mentioned_users.length > 0 ? editDetails.mentioned_users[0].exemplify_id : null,
      activeLink: 0,
      showConnectDevice: false,
      showPhotoPostModal: false,
      showAllRecogntion: false,
      user: null,
      ShowEmojiContainer: false,
      displayEmoji: false,
      usersV2: [],
      userIdArrayV2: editPostStatus && editDetails.mentioned_users.length > 0 ? this.getTaggedUserDetails(editDetails.mentioned_users, 0) : [],
    };
    this.myref = React.createRef();
    this.emojiref = React.createRef();

  }

  getTaggedUserDetails = (users, status = 1) => {
    let taggedUser = '';
    let userIds = [];
    let userNames = [];
    users.forEach((user, index) => {
      userIds.push(user.uid);
      userNames.push(user.fullname);
      taggedUser += (index === users.length - 1) ? user.fullname : `${user.fullname}, `
    })
    return status === 3 ? userNames : status === 1 ? taggedUser : userIds;
  }

  getImageDetails = (users) => {
    let images = [];
    users.forEach((user) => {
      images.push(`${ImageUrl}/${user.image}`);
    })
    return images;
  }

  handleClick = (event) => {
    if (!isNull(this.myref) && !isUndefined(this.myref) && !isNull(this.myref.current) && !isUndefined(this.myref.current) && !(this.myref && this.myref.current && this.myref.current.contains(event.target))) {
      this.setState({ showConnectDevice: false });
    }
  };
  handleEmojiClick = (event) => {
    if (!isNull(this.emojiref) && !isUndefined(this.emojiref) && !(this.emojiref && this.emojiref.current && this.emojiref.current.contains(event.target))) {
      // this.setState({displayEmoji: false});
    }
  };

  handleRecogntionPopup = () => {
    this.setState((prev) => ({
      showAllRecogntion: !prev.showAllRecogntion,
    }));
  };

  displayEmojiContainer = () => {
    this.setState((prev) => ({
      ShowEmojiContainer: !prev.ShowEmojiContainer
    }));
  }

  showEmojiPicker = () => {
    this.setState((prev) => ({
      displayEmoji: !prev.displayEmoji
    }))
  }
  hideEmojiPicker = () => {
    this.setState({
      displayEmoji: false
    })
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
    document.addEventListener("mousedown", this.handleEmojiClick);

    const { profileImage, fetchUserProfileImage, getSuggestionsList, fetchConnectedDevices, getCompanyExemplifiesValues, getUserProfileDetails } = this.props;
    getSuggestionsList();
    getUserName();
    getUserProfileDetails();
    if (!profileImage) {
      fetchUserProfileImage();
    }
    fetchConnectedDevices();
    getCompanyExemplifiesValues();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
    document.addEventListener("mousedown", this.handleEmojiClick);

  }

  displayDeviceModal = () => {
    this.props.fetchConnectedDevices();
    this.setState({ showDeviceModal: true })
  };

  openEditAndDelete = () => {
    this.setState({
      editAndDelete: !this.state.editAndDelete
    })
  };

  changeEditDeletePopup = (value, feed) => {
    this.setState({ editDetails: feed }, () => {
      this.setState({ showEditDeletePopup: value });
    });
  };

  onHideEditDeletePopup = () => {
    this.setState({
      showEditDeletePopup: ''
    }, () => {
      this.openEditAndDelete();
    })
  };
  removeImage = (src) => {
    let arrayy = this.state.imgSrcArray.filter((item) => item !== src)
    this.setState({
      imgSrcArray: arrayy,
      refreshFileReader: false
    });
  }

  onChange = (e) => {
    let files = e.target.files;
  
    if (files && files.length > 0) {
      for (let i = 0; i < Math.min(files.length, 5); i++) {
        if (checkImage(files)) {
          if ((files[i]?.size / 1000000) <= 20) {
            let reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onloadend = () => {
              this.setState({
                imgSrcArray: [...this.state.imgSrcArray, reader.result],
                refreshFileReader: true
              });
            };
          } else {
            toast.error('Please select image file less than 20MB');
            document.getElementById('uploadPhoto') && (document.getElementById('uploadPhoto').value = '');
          }
        } else {
          toast.error(imageErrorMessage);
          document.getElementById('uploadPhoto') && (document.getElementById('uploadPhoto').value = '');
        }
      }
      // Reset the input value to allow re-selection of the same file
      e.target.value = '';
    }
  };

  toCodePoints = function (string) {
    let chars = "";
    for (let i = 0; i < string.length; i++) {
      let c1 = string.charCodeAt(i);
      let b1 = string.charAt(i);
      if (c1 >= 0xD800 && c1 < 0xDC00 && i + 1 < string.length) {
        let c2 = string.charCodeAt(i + 1);
        if (c2 >= 0xDC00 && c2 < 0xE000) {
          chars = chars + ("&#" + ((+0x10000 + ((c1 - 0xD800) << 10) + (c2 - 0xDC00))) + ";");
          i++;
          continue;
        }
      }
      else {
        chars = chars + b1;
      }
    }
    return chars;
  }

  getCommentsForPost = (id) => {
    const { fetchCommentsOnPost } = this.props;
    this.setState({
      showCommentsFor: id
    });
    fetchCommentsOnPost(id);
  };

  onScroll = (e) => {
    const { pageNumber } = this.state;
    const { count, feeds } = this.props;
    const bottom = Math.round(e.target.scrollHeight - parseInt(e.target.scrollTop,10)) === Math.round(e.target.clientHeight);
    if (bottom && feeds &&feeds.response&& feeds.response.length < count) {
      this.setState({
        pageNumber: pageNumber + 1
      }, () => {
        this.props.loadMoreFeeds(this.state.pageNumber);
      });
    }
  };

  renderFeeds = (feeds) => {
    const { showCommentsFor, editAndDelete } = this.state;
    const { isLoading, comments, replySuccess, postReplyOnComment, profileImage, history, enableTrigger } = this.props;
    return (
      <div>
        {
          feeds && feeds.length > 0 ?
            <ScrollableFeedsContainer onScroll={this.onScroll}>
              {
                feeds.map((feed, index) => (
                  <FeedsContainer key={index}>
                    <SingleFeed
                      feed={feed}
                      description={feed.description}
                      taggedUsers={feed.shoutout_user_name}
                      getCommentsForPost={this.getCommentsForPost}
                      changeEditDeletePopup={this.changeEditDeletePopup}
                      editAndDelete={editAndDelete}
                      openEditAndDelete={this.openEditAndDelete}
                      profileImage={profileImage}
                      updationList="response"
                      commentCount={feed.comment_count}
                      history={history}
                      enableTrigger={enableTrigger}
                    />
                    {showCommentsFor === feed.id &&
                      (isLoading ? <Loading isHorizontal /> :
                        (isEmpty(comments) ? <NoComments>No Comments to display</NoComments> :
                          <Comments
                            comments={comments}
                            postReply={postReplyOnComment}
                            replySuccess={replySuccess}
                            id={feed.id}
                            history={history}
                            enableTrigger={enableTrigger}
                          />))}
                  </FeedsContainer>
                ))
              }
            </ScrollableFeedsContainer> : <NoComments>No posts in this section</NoComments>

        }
      </div>
    )
  };

  addInspiration = (e) => {
    const text = e.target.value;
  
    // Allow user to remove characters if they exceed the limit
    if (text.length <= 500) {
      let position = this.getPosition(e.target);
      this.setState({
        position: position,
        inspirationQuote: text,
      });
    } else {
      // Prevent adding more characters beyond the limit
      let position = this.getPosition(e.target);
      this.setState({
        position: position,
        inspirationQuote: text.slice(0, 500),
      });
    }
  };
  
  getPosition(el) {
    let start = 0, end = 0, normalizedValue, range,
      textInputRange, len, endRange;

    if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
      start = el.selectionStart;
      end = el.selectionEnd;
    } else {
      range = document.selection.createRange();

      if (range && range.parentElement() == el) {
        len = el.value.length;
        normalizedValue = el.value.replace(/\r\n/g, "\n");
        textInputRange = el.createTextRange();
        textInputRange.moveToBookmark(range.getBookmark());
        endRange = el.createTextRange();
        endRange.collapse(false);

        if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
          start = end = len;
        } else {
          start = -textInputRange.moveStart("character", -len);
          start += normalizedValue.slice(0, start).split("\n").length - 1;

          if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
            end = len;
          } else {
            end = -textInputRange.moveEnd("character", -len);
            end += normalizedValue.slice(0, end).split("\n").length - 1;
          }
        }
      }
    }

    return {
      start: start,
      end: end
    };
  }
  addInspirationV2 = (e) => {
    let newText = this.state.inspirationQuote.substr(0, this.state.position.start) + e + this.state.inspirationQuote.substr(this.state.position.end);
    this.setState({
      position: { start: this.state.position.start + e.length, end: this.state.position.end + e.length },
      inspirationQuote: newText,
    });
  };

  updateText = (text, userId, taggedUserName) => {
    const { userIdArray, users, taggedUser } = this.state;
    let userIdArr = [...userIdArray];
    let userNameArr = [...users];
    let taggedUsers = taggedUser;
    if (userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userNameArr.push(taggedUserName);
      if (_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      // else
      // taggedUsers = taggedUserName;
    }
    this.setState({
      inspirationQuote: text,
      userIdArray: userIdArr,
      users: userNameArr,
      position:{start:text.length,end:text.length}
      // taggedUser: taggedUsers,
      // showGivenShoutout: bool
    });
  };

  updateTextV2 = (text, userId, taggedUserName, bool) => {
    const { userIdArrayV2, usersV2, taggedUser, userIdArray } = this.state;
    let userIdArr = [...userIdArrayV2];
    let userNameArrV2 = [...usersV2];
    let userIdAll = [...userIdArray]
    let taggedUsers = taggedUser;
    if (userIdArr.indexOf(userId) === -1) {
      userIdArr.push(userId);
      userIdAll.push(userId);
      userNameArrV2.push(taggedUserName);
      if (!_.isEmpty(taggedUsers))
        taggedUsers = `${taggedUsers}, ${taggedUserName}`;
      else
        taggedUsers = taggedUserName;
    }
    this.setState({
      userIdArrayV2: userIdArr,
      usersV2: userNameArrV2,
      taggedUser: taggedUsers,
      showGivenShoutout: bool,
      userIdArray: userIdAll
    });
  };

  onShoutoutSelect = (text, id, image) => {
    const { userIdArray } = this.state;
    this.updateShoutoutArray(userIdArray, text, image, id);
  };

  updateShoutoutArray = (mentionedUsers, text, image, exemplify_id) => {
    this.setState({
      mentionedUsers: mentionedUsers,
      showGivenShoutout: true,
      shoutoutText: text,
      shoutoutImage: image,
      shoutoutType: exemplify_id ? exemplify_id : null,
      exemplifyID: exemplify_id ? exemplify_id : null,
      showAllRecogntion: !this.state.showAllRecogntion,

    });
  };

  onRemoveShoutout = (array) => {
    this.setState({
      [array]: [],
      showGivenShoutout: false,
      taggedUser: '',
      shoutoutImage: null,
      shoutoutText: null,
      userIdArray: [],
      users: [],
      shoutoutType: 0,
      mentionedUsers: [],
      usersV2: '',
      userIdArrayV2: []
    });
  };

  onRemoveUser = () => {
    this.setState({
      taggedUser: '',
      showGivenShoutout: false,
      userIdArray: [],
      shoutoutImage: null,
      shoutoutText: null,
      users: []
    });
  };

  onHide = () => {
    this.setState({
      imgSrc: ''
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.profileImage != nextProps.profileImage) {
      this.checkBase64(nextProps.profileImage);
    }
  }

  checkBase64(profileImage) {
    if (profileImage) {
      let arr = profileImage.split('/');
      if (arr[0] !== 'profile-Image') {
        this.setState({
          isUploadedImage: true
        })
      }
      else {
        this.setState({
          isUploadedImage: false
        })
      }
    }
  }

  addPost = () => {
    const { imgSrcArray, mentionedUsers, usersV2, userIdArray, userIdArrayV2, inspirationQuote, taggedUser, exemplifyID } = this.state;
    const { addSocialInspiration, activeTab, onClose, editDetails, editPostStatus, editSocialInspiration, addCommentWithImageStatus, postComment, feedIdForComment, postReplyOnComment, commentId } = this.props;
    let inspirationData = {
      image: imgSrcArray.length > 0 ? imgSrcArray[0] : '',
      post_images: imgSrcArray.length > 0 ? imgSrcArray : [],
      exemplify_id: exemplifyID,
      shoutout: {
        exemplifies: userIdArrayV2
      },
      mentioned_user: userIdArray
    };
    if (addCommentWithImageStatus) {
      inspirationData.comment = inspirationQuote;
      inspirationData.post_id = feedIdForComment;
    } else {
      let html = this.toCodePoints(inspirationQuote);
      inspirationData.description = html;
    }
    if (!_.isEmpty(mentionedUsers) || !_.isEmpty(userIdArray) && usersV2) {
      inspirationData.shoutout_user_name = `${applyHighlights(usersV2, userIdArrayV2, taggedUser)} - ${this.state.shoutoutText}`;
    }
    this.onHide();
    if (editDetails && editPostStatus) {
      editSocialInspiration({
        ...inspirationData,
        recognition_image: editDetails.recognition_image,
        recognition_string: editDetails.recognition_string,
        recognition_text: editDetails.recognition_text,
        shoutout_type: editDetails.shoutout_type,
        id: editDetails.id,
      }, {
        ...inspirationData,
        recognition_image: editDetails.recognition_image,
        recognition_string: editDetails.recognition_string,
        recognition_text: editDetails.recognition_text,
        shoutout_type: editDetails.shoutout_type,
        id: editDetails.id,
      });
    }
    else if (addCommentWithImageStatus === 1) {
      if (feedIdForComment !== '' || inspirationQuote !== '' || inspirationData.description !== "" || inspirationData.image !== "" || inspirationData.shoutout.exemplifies.length >= 1) {
        postComment(inspirationData);
      } else {
        toast.error("Please add the comment details for the post before the send the comment");
      }
    } else if (addCommentWithImageStatus === 2) {
      if (feedIdForComment !== '' || inspirationQuote !== '' || inspirationData.description !== "" || inspirationData.image !== "" || inspirationData.shoutout.exemplifies.length >= 1) {
        postReplyOnComment(commentId, inspirationQuote, feedIdForComment, inspirationData.shoutout, inspirationData.shoutout_user_name, exemplifyID, inspirationData.image);
      }
    }
    else {
      if (inspirationQuote !== '' || inspirationData.description !== "" || inspirationData.image !== "" || inspirationData.shoutout.exemplifies.length >= 1) {
        addSocialInspiration(inspirationData);
      } else {
        toast.error("Please add all the post details before posting");
      }
    }
    let active = activeTab === "Company" ? "Company" : activeTab === "My Amigos" ? "My Amigos": "Everybody";
    this.props.setActiveTab(active);
    this.setState({
      inspirationQuote: '',
      imgSrc: '',
      showGivenShoutout: false,
      shoutoutImage: null,
      shoutoutText: null,
      taggedUser: '',
      userIdArray: [],
      users: [],
      mentionedUsers: [],
      shoutoutType: 0,
    })
    onClose();
  };



  handleTabSwitch = (index) => {
    this.setState({
      activeLink: index
    })
  }

  toggleConnectDevices = () => {
    this.setState((prev) => ({
      showConnectDevice: !prev.showConnectDevice
    }));
  };

  handlePhotoPostModal = () => {
    this.setState((prev) => ({
      showPhotoPostModal: !prev.showPhotoPostModal
    }));
  };
  
  handleSelect = (key) => {
    this.props.setActiveTab(key);
  };

  image = () => (
    this.state.imgSrcArray.map((src, i) => (
      (
        <div className='imageDiv' key={i} >
          <img alt='img' width='150px' height='150px' src={src} />
          <span className='remove' onClick={() => this.removeImage(src)} style={{background:"white", borderRadius:"50%", height:"24px", width:"24px", justifyContent:"center", alignItems:"center", display:"flex"}}><img width='18px' height="18px" src={ImageUrl + "/social-feed/imageCross.png"}></img></span>
        </div>
      )
    )))

  render() {
    const { profileImage, showModal, onClose, t } = this.props;
    return (
      <div>
        <StyledModal show={showModal} onHide={onClose} width="800px">
          <StyledHeader background border>
            {<HeaderText color>{t('New Post')}</HeaderText>}
            <CloseIcon onClick={onClose}>
              {feedCloseIcon()}
            </CloseIcon>
          </StyledHeader>
          <StyledBody>
            <TopSectionV2 disable={this.state.imgSrcArray.length===0&&!this.state.inspirationQuote&&this.state.userIdArrayV2.length===0?1:0} background>
              <div className='user-details'>
                <div className='profile'>
                  <img src={`${ImageUrl}/${profileImage}`} />
                </div>
                <div className='name'>{this.props.user.fname} {this.props.user.lname}</div>
              </div>
              <SocialFeedTextarea
                userCompany={this.props.userCompany}
                addInspiration={this.addInspiration}
                addInspirationV2={this.addInspirationV2}
                inspirationQuote={this.state.inspirationQuote}
                updateText={this.updateText}
                updateTextV2={this.updateTextV2}
                users={this.state.users}
                usersV2={this.state.usersV2}
                userIdArray={this.state.userIdArray}
                placeholder={t("Write here. You can also include @mentions.")}
                className={'CustomTextAreaField2'}
                showGivenShoutout={this.state.showGivenShoutout}
                updateShoutoutArray={this.updateShoutoutArray}
                emptyShoutoutArray={this.emptyShoutoutArray}
                onRemoveShoutout={this.onRemoveShoutout}
                shoutoutText={this.state.shoutoutText}
                shoutoutImage={this.state.shoutoutImage}
                taggedUser={this.state.taggedUser}
                onRemoveUser={this.onRemoveUser}
                enableTrigger={this.props.enableTrigger}
                ShowEmojiContainer={this.state.ShowEmojiContainer}
                showEmojiPicker={this.showEmojiPicker}
                displayEmoji={this.state.displayEmoji}
                emojiref={this.emojiref}
                hideEmojiPicker={this.hideEmojiPicker}
              />
              <PostButtonWrapper>
                <div className='emojiIcon' onClick={this.showEmojiPicker}>
                  <img src={`${ImageUrl}/${"social-feed/emojiicon.svg"}`} />
                </div>
                <div className='post-button'>
                  <PostButtonCard disable={!this.state.inspirationQuote&&this.state.userIdArrayV2.length===0} onClick ={!this.state.inspirationQuote&&this.state.userIdArrayV2.length===0? null:this.addPost}>
                    <span>Post</span>
                    {LaunchEventIcon()}
                  </PostButtonCard>
                </div>
                <div className='text-count'>
                  {this.state.inspirationQuote?.length}/500
                </div>
              </PostButtonWrapper>
            </TopSectionV2>
          </StyledBody>
        </StyledModal>
      </div>
    )
  }
}

WritePost.propTypes = {
  feeds: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  addSocialInspiration: PropTypes.func.isRequired,
  friendRequests: PropTypes.array.isRequired,
  acceptOrRejectFriendRequest: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  activityFeeds: PropTypes.array.isRequired,
  comments: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  fetchCommentsOnPost: PropTypes.func.isRequired,
  postReplyOnComment: PropTypes.func.isRequired,
  replySuccess: PropTypes.string,
  loadMoreFeeds: PropTypes.func.isRequired,
  wellnessExpert: PropTypes.object,
  iswbaTaken: PropTypes.bool.isRequired,
  profileImage: PropTypes.string,
  fetchUserProfileImage: PropTypes.func,
  count: PropTypes.number,
  role: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  fetchUsersToShoutout: PropTypes.func,
  userCompany: PropTypes.object.isRequired,
  employeeData: PropTypes.array,
  enableTrigger: PropTypes.bool,
  userPermissions: PropTypes.array,
  companyId: PropTypes.number.isRequired,
  suggestionList: PropTypes.array,
  getSuggestionsList: PropTypes.func,
  user: PropTypes.object,
  devices: PropTypes.array,
  revokeDevice: PropTypes.func.isRequired,
  devicesDisconnected: PropTypes.array,
  fetchConnectedDevices: PropTypes.func.isRequired,
  dailyTip: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  getCompanyExemplifiesValues: PropTypes.func,
  exemplifiesValues: PropTypes.array,
  editDetails: PropTypes.any,
  editPostStatus: PropTypes.number,
  editSocialInspiration: PropTypes.func,
  postComment: PropTypes.func,
  addCommentWithImageStatus: PropTypes.number,
  feedIdForComment: PropTypes.any,
  commentId: PropTypes.any,
  t: PropTypes.func,
  getUserProfileDetails: PropTypes.func.isRequired,
  showRecognitionPopup: PropTypes.func,
  hidePhotoVideoPopup: PropTypes.func,
  handlePeerModal: PropTypes.func
};

const mapStateToProps = (state) => ({
  isLoading: state.social.isLoading,
  comments: state.social.comments,
  replySuccess: state.social.replySuccess,
  profileImage: state.profileData.profileImage,
  count: state.social.count,
  employeeData: state.peopleHome.employeeData,
  userCompany: state.profileData.userCompany,
  userPermissions: state.profileData.userPermissions,
  suggestionList: state.social.suggestionList,
  devices: state.wellnessDashboard.devices,
  devicesDisconnected: state.profileData.devicesDisconnected,
  dailyTip: state.profileData.dailyTip,
  exemplifiesValues: state.social.exemplifiesValues,
  user: state.profileData.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersToShoutout: (data, cb) => dispatch(getUsersToShoutout(data, cb)),
  addSocialInspiration: (inspirationData) => dispatch(addSocialInspiration(inspirationData)),
  fetchCommentsOnPost: (id) => dispatch(getCommentsOnPost(id)),
  fetchUserProfileImage: () => dispatch(getUserProfileImage()),
  postReplyOnComment: (commentId, reply, postId, shoutout, shoutOutUserName, exemplify_id, image) => dispatch(addCommentReplyOnPost(commentId, reply, postId, shoutout, shoutOutUserName, exemplify_id, image)),
  getSuggestionsList: () => dispatch(getSuggestionsList()),
  fetchConnectedDevices: () => dispatch(getListOfConnectedDevices()),
  revokeDevice: (name) => dispatch(revokeDevice(name)),
  getCompanyExemplifiesValues: () => dispatch(getCompanyExemplifiesValues()),
  editSocialInspiration: (postDetails, updationList) => dispatch(editSocialInspiration(postDetails, updationList)),
  postComment: (commentData, updationList) => dispatch(addCommentOnPost(commentData, updationList)),
  getUserProfileDetails: () => dispatch(getUserProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WritePost));