import styled from 'styled-components';
import {Modal} from "react-bootstrap";

const StyledModal = styled(Modal)`
@media (min-width: 576px){
  .modal-dialog {
    max-width:100% !important;
  }}
  .modal-dialog {
    width: ${({width}) => width ? width : '485px'};
    border-radius: 8px;
    background: linear-gradient(180deg, #FFFFFF 0%,rgba(255,255,255,0) 100%), #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    transform: translate(0, 50vh) translate(0, -50%);
    margin: 80px auto;
    border: none;
    @media (min-width: 576px){
      .modal-dialog {
        max-width:100% !important;
      }}
    .modal-content {
      height: 100%;
      width:100%;
      border: none;
      background-color: ${({bgcolor}) => (bgcolor || !bgcolor) ? 'transparent' : '#fff'};
    }
    .close {
      margin-top: -30px;
    }
    ${'' /* @media (max-height: 1200px) {
      width: 55%;
    } */}
    @media (max-height: 530px) {
      transform: none !important;
      margin: 30px auto;
    }
    @media (max-width: 766px) {
      width: 95%;
    }
  }
`;

const StyledHeader = styled(Modal.Header)`
  display: flex;
  padding: 16px;
  border-bottom: none;
  border-bottom: ${({border}) => border ? '1px solid rgba(0, 92, 135, 0.30) !important' : 'none'};
  background: ${({background}) => background ? 'transparent' : 'linear-gradient(180deg, #52A8ED 0%, #8AD3F7 100%)'};
  border-radius: 6px 6px 0px 0px;
  text-align: center;
  align-items: center;
  height: ${({ height }) => height ? height : '50px'};
  > span {
    font-size: 18px;
    font-family: 'Rubik-Medium';
    color: #0D4270;
    text-decoration: underline;
    text-transform: uppercase;
  }
`;
const StyledBody = styled(Modal.Body)`
height: 100%;
width: 100%;
padding: ${({padding}) => padding ? "24px 10px 24px 24px !important" : '24px !important'};
`;
const ContentContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(248,168, 135, 0.1);
  padding: 40px;
  border-radius: 6px;
  @media (max-width: 400px) {
    padding: 10px;
  }
  
  > span {
    text-align: center;
    font-size: 16px;
    color: #FD7175;
    font-family: 'Rubik-Regular';
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 60px;
`;
const TipsButton = styled.button`
  width: 65%;
  height: 50px;
  outline: none;
  border: none;
  background-color: #FD7175;
  border-radius: 6px;
  > span {
    color: #FFFFFF;
    font-family: 'Rubik-Medium';
    text-transform: uppercase;
    font-size: 18px;
  }
`;

const CloseIcon = styled.div`
    width: 5%;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const HeaderText = styled.div`
    font-family: Rubik-Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${({color}) => color ? '#005c87' : '#005C87'};
    width: 95%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-content:center;
    padding: ${({padding}) => padding ? padding : ''};
    >img{
      width:24px;
      height:24px;
      margin-right:12px;
    }
`;

const SendIcon = styled.div`
    width: 10%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TopSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .user-namee{
    margin-left: 15px;
    margin-top: 14px;
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 22px;
    color: #005c87; 
  }
  .add-post-box{
    width: 100%;
    height: 60px;
    background: rgba(156, 156, 156, 0.1);
    border: 1px solid rgba(156, 156, 156, 0.4);
    border-radius: 4px;
    margin-top: 15px;
    position: relative;
    >div{
      .box-title{
        font-family: 'Rubik-Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #005c87;
      }
    }
  }
  .post-btn{
    background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
    background: ${({background}) => background ? '#005c87' : 'linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%)'};
    border-radius: 3px;
    width: 100%;
    height: 50px;
    margin-top: 25px;
    border: none;
    opacity: ${({disable}) => (disable) ? '0.5' : ''};
    cursor: ${({disable}) => (disable) ? 'not-allowed !important' : 'pointer'};
    .btn-title{
      font-family: 'Rubik-Medium';
      font-size: 18px;
      line-height: 24px;
      color: #FFFFFF;
    }
    .btn-title-main{
      text-align: center;
      display: flex;
      justify-content: center;
      .btn-title{
        font-family: 'Rubik-Medium';
        font-size: 18px;
        line-height: 24px;
        color: #FFFFFF;
      }
      >img{
        margin-left:15px;
      }
    }
  }
  >div:first-child{
    width:10%
  }
  .CustomTextAreaField2 {
      line-height: 30px;
      padding: 25px 0px 0px 0px;
   }
  >div:nth-child(2){
    padding: 0 0px;
    width: 100%;
    >div:nth-child(2){
      >div:first-child{
        >textarea{
          border: none;
          width: 100%;
          font-size: 18px;
          line-height: 22px;
          color: #005c87;
          &:focus, &:active, &:hover, &:focus-visible {
            border: none !important;
            outline: none !important;
          }
          ::placeholder {
            color: #005C8799;
          } 
      
          :-ms-input-placeholder {
            color: #005C8799;
          }
      
          ::-ms-input-placeholder {
            color: #005C8799;
          } 
        }
      }
    }
  }

  .camera{
    display: flex;
    width: 14%;
    text-align: right;
    position: relative;
    cursor: pointer;
    >img{
      width: 70px;
      height: 70px;
    }
    input{
      position: absolute;
    }
    [hidden] {
      display: none !important;
    }
  }

`;

const MiddleSection = styled.div`
    overflow: hidden;
    height: 235px;
    position: absolute;
    top: -235px;
    left: 160px; 
    border-radius:6px;
    background: white;
    display: flex;
    flex-wrap: wrap;
    width: 400px;
    overflow-y: scroll;
`;
const ImageSection = styled.div`
    width: ${({width}) => width ? '40px' : '100%'};
    .imageContainer {
      width: 100%;
      // min-height: 181px;
      height:auto;
      padding:10px 10px 0 10px;
      flex-wrap:wrap;
      display: flex;
      // position: absolute;
      // margin-bottom: 20px;
      justify-content: flex-start;
      right: 0px;
      top: 110px;
      // padding-left: 15px;
      // padding-bottom: 5px; 
      align-items: center;
      overflow: hidden;
      background: rgba(156, 156, 156, 0.05);
      border: 1px solid rgba(156, 156, 156, 0.4);
      border-radius: 6px;
      .imageDiv{
        height:150px;
        width:149px;
        position:relative;
        margin-bottom:10px;
        margin-right: 10px;
      .remove{
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
      }}
    }
`;

const UploadImage3 = styled.label`
  width: 144px;
  height:144px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  cursor: pointer;
  border: none;
  background-color:rgba(0, 92, 135, 0.05);
  border-radius: 6px;

  >span{
    font-family: 'Rubik-Medium';
    font-size: 18px;
    font-weight: 500;
  }
  input{
    position: absolute;
  }
  [hidden] {
    display: none !important;
  }
`;
const CommentSection = styled.div`
  width: 100%;
  float: left;
  padding: 10px;
  border-radius: 6px 6px 0 0;
`;

const PeerButton = styled.div`
    height: 50px;
    padding: 10px 15px;
    cursor: pointer;
    margin-bottom: 5px;
    background: #F9F9F9;
    border-radius: 6px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 180px;
    margin: 5px;
    >img{
      width: 25px;
      height: 25px;
    }
    >div{
        width: 90%;
        text-align: left;
        display: flex;
        justify-content: left;
        padding-left: 15px;
        align-items: center;
        font-size: 16px;
    }
`;

const UploadImage2 = styled.label`
  // width: 100%;
  // border: 1px solid #EAEAEA;
  // border-radius: 4px;
  // padding: 11px 25px;
  // height: 50px;
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  // position: relative;
  // margin-bottom: 20px;
  cursor: pointer;
  border: none;
  >img{
    width: 25px;
    height: 25px;
    // margin-right: 18px;
  }
  >span{
    font-family: 'Rubik-Medium';
    font-size: 18px;
    font-weight: 500;
  }
  input{
    position: absolute;
  }
  [hidden] {
    display: none !important;
  }
`;

const PostButtonWrapper = styled.div`
width:100%;
display:flex;
margin: ${({margin, marginValue}) => marginValue?marginValue: (margin) ? '24px 0 !important' : '0'};
.emojiIcon{
  width:48px;
  height:48px;
  border-radius:6px;
  cursor:pointer;
  display:flex;
  background:rgba(0, 92, 135, 0.05);
  >img{
    width:24px;
    height:24px;
    display:flex;
    margin:auto;
  }
}
.text-count{
  width:65px;
  display:flex;
  color: rgba(0, 92, 135, 0.30);
  text-align: right;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  align-items: center;
}
.post-button{
  display:flex;
  justify-content:center;
  margin:auto;
}
`;

const PostButtonCard = styled.div`
  width: 280px;
  padding:12px;
  display:flex;
  align-items:center;
  justify-content:center;
  background: ${({disable}) => disable ? 'rgba(0, 122, 177, 0.32)' : '#007AB1'};
  cursor: ${({disable}) => disable ? 'not-allowed' : 'pointer'};
  border-radius:4px;
  color: #FFF;
  text-align: center;
  font-family: Rubik-Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height:24px;
  >span{
    margin-right:12px;
  }
`;

const TopSectionV2 = styled.div`
width: 100%;
display: flex;
flex-direction: column;
.max-image-text{
  width:100%;
  display:flex;
  color: #005C87;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  margin-top:20px;
}
.user-details{
  display:flex;
  .profile{
    width:48px;
    height:48px;
    border-radius:50%;
    >img{
      width:100%;
      height:100%;
      border-radius:50%;
    }
  }
  .name{
    color: #007AB1;
    text-align: center;
    font-family: Rubik-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display:flex;
    margin-left: 12px;
    align-items: center;
  }
}
>div:nth-child(2){
  padding: 0 0px;
  width: 100%;
  >div:nth-child(2){
    >div:first-child{
      >textarea{
        border: none;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
        color: #005c87;
        resize:none;
        margin: 24px 0px;
        &:focus, &:active, &:hover, &:focus-visible {
          border: none !important;
          outline: none !important;
        }
        ::placeholder {
          color: #005C874D;
        } 
    
        :-ms-input-placeholder {
          color: #005C874D;
        }
    
        ::-ms-input-placeholder {
          color: #005C874D;
        } 
      }
    }
  }
}
`;

const TextEditorWrapper = styled.div`
width:100%;
>textarea{
  border: none;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: #005c87;
  resize: none;
  margin: 24px 0px;
}
`;

const AddImageWrapper = styled.div`
width:100%;
display:flex;
flex-wrap:wrap;
gap: 8px;
margin-top:24px;
.imageDiv{
  height:144px;
  width:144px;
  position:relative;
  >img{
    border-radius:4px;
  }
.remove{
  position: absolute;
  top: -4px;
  right: -4px;
  cursor: pointer;
}}
`;

const ImagePopupWrapper = styled.div`
width:100%;
display:block;
// margin-top:24px;
overflow-y: auto;
overflow-x: hidden;
height:388px;
.user-details{
  display:flex;
  padding:0px !important;
  margin-bottom:24px !important;
  .profile{
    width:48px;
    height:48px;
    border-radius:50%;
    >img{
      width:100%;
      height:100%;
      border-radius:50%;
    }
  }
  .name{
    color: #007AB1;
    text-align: center;
    font-family: Rubik-Medium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    display:flex;
    margin-left: 12px;
    align-items: center;
  }
}
::-webkit-scrollbar {
  width: 7px;
  height: 45px;
}
::-webkit-scrollbar-thumb {
  background: #005C87;
  cursor: pointer;
  border-radius: 2.5px;
}

::-webkit-scrollbar-track {
  background: #005C874D;
  border-radius: 2.5px;
}
`;

export {
  StyledModal, StyledHeader, StyledBody, ContentContainer, ButtonContainer, TipsButton, CloseIcon, HeaderText, SendIcon, TopSection, MiddleSection, CommentSection, PeerButton, ImageSection, UploadImage2, UploadImage3, PostButtonWrapper, PostButtonCard, TopSectionV2, TextEditorWrapper, AddImageWrapper, ImagePopupWrapper
}
